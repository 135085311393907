import { get } from 'lodash'
import AppImage from '@theme/components/atom/AppImage'
import BadgeWidgets from '@theme/components/shop/BadgeWidgets'
import PageCatalog from '~/themes/base/components/product/PageCatalog'
import TimeLimitedOfferBox from '@theme/components/product/TimeLimitedOfferBox'

export default {
  extends: PageCatalog,
  components: {
    AppImage,
    BadgeWidgets,
    TimeLimitedOfferBox,
  },
  computed: {
    categoryLinkBanner() {
      return this.$store.getters['globals/secondaryBanner']
    },
    categoryLinkBannerImage() {
      return get(this.categoryLinkBanner, 'image[0]') || {}
    },
    catalogItems() {
      const items = PageCatalog.computed.catalogItems.apply(this)

      if (false && this.showTimeLimitedOffer && !this.isSearch && (!this.isFiltered || this.filteredCategory)) {
        items.splice(1, 0, {
          type: 'limited_offer',
        })
      }

      return items
    },
    hasCategoryLinkBanner() {
      return this.$store.getters['globals/isSecondaryBannerEnabled']
    },
    displayFilteredEmptyBrandText() {
      return !!this.catalogState.parameters.find(p => p.slug === 'vyrobce' && p.isSelected && p.values.length > 0)
    },
    filteredEmptyBrand() {
      if (this.displayFilteredEmptyBrandText) {
        return this.catalogState.parameters.find(p => p.slug === 'vyrobce' && p.isSelected).values[0].names.cs
      }
      return ''
    },
    filteredEmptyBrandUrl() {
      const brandSlug = this.catalogState.parameters.find(p => p.slug && p.isSelected).values[0].slug_translations.cs
      return '/' + brandSlug
    },
    filteredEmptyCategory() {
      return this.content.baseTitle
    },
    filteredEmptyCategoryUrl() {
      return '/' + this.categoryStore.slug
    },
    isBrand() {
      const brands = get(this.virtualCategoryCms, 'parameters', []).filter(
        v => v.entityId && v.entityId === this.$contentSettings.brandParameterId,
      )
      return brands && brands.length > 0 && this.virtualCategoryCms.title
    },
    showTimeLimitedOffer() {
      const content = this.categoryCms || this.virtualCategoryCms || {}
      return (
        this.$themeSettings.components.PageCatalog.showTimeLimitedOfferBox &&
        content?.limited_offer_reference?.[0]?.entity?.domain_enabled?.includes(this.$store.state.globals.domain.code)
      )
    },
    timeLimitedOffer() {
      const content = this.categoryCms || this.virtualCategoryCms || {}
      return this.showTimeLimitedOffer && content.limited_offer_reference[0].entity
    },
  },
  methods: {
    getSeoLinkCanonical() {
      let link = []
      if (this.page > 0) {
        link.push({
          rel: 'prev',
          href: this.makeAbsoluteUrl(
            this.catalogLink({
              slug: this.$store.state.catalog.slug,
              catalogState: this.catalogState,
              page: this.page - 1,
            }).url,
          ),
        })
      }
      if (this.page < this.pages.length - 1) {
        link.push({
          rel: 'next',
          href: this.makeAbsoluteUrl(
            this.catalogLink({
              slug: this.$store.state.catalog.slug,
              catalogState: this.catalogState,
              page: this.page + 1,
            }).url,
          ),
        })
      }

      link.push({
        rel: 'canonical',
        href: this.canonicalLinkUrl({ path: this.$route.path }),
      })

      return link
    },
    saveScrollPosition() {
      this.$store.dispatch('catalog/SAVE_SCROLL_POSITION')
    },
  },
  mounted() {
    this.$nextTick(() => {
      const scrollPosition = this.$store.state.catalog.scrollPosition

      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, scrollPosition)
        }, 50)
      }
    })
  },
}
